const BASE_URL = process.env.REACT_APP_BASE_URL;

export const userToken = localStorage.getItem("userToken");

// configure header's Content-Type as JSON
export const config = {
  headers: {
    ContentType: "application/json",
    accept: "application/json",
    Authorization: `Bearer ${userToken}`,
  },
};

export const APP_URLs = {
  mediaUpload: `${BASE_URL}/media/uploadpicture`,
  mediaUploadTFLite: `${BASE_URL}/media/uploadtflite`,
  multiMediaUpload: `${BASE_URL}/media/uploadMultiplePicture`,
  awsS3ImageURL: "https://s3.ap-south-1.amazonaws.com/mobmaximetestbucket/",
  authEndPoints: {
    login: `${BASE_URL}/auth/signin`,
    logout: `${BASE_URL}/admin/logout`,
  },
  changeUserPassword: {
    genericAPI: `${BASE_URL}/user/change-password`,
  },
  commonEndPoints: {
    getCountries: `${BASE_URL}/list/country`,
    getStates: `${BASE_URL}/list/state`,
    getCities: `${BASE_URL}/auth/getCities`,
  },
  appUser: {
    appCustomerList: `${BASE_URL}/admin/userList`,
    addAdmin: `${BASE_URL}/admin/create-admin`,
    editAdmin: `${BASE_URL}/admin/edit-details`,
    suspendAdmin: `${BASE_URL}/admin/suspend-user`,
  },
  users : {
    appAllUsersList: `${BASE_URL}/user/all-users`,
    deleteUsers: `${BASE_URL}/user/delete-user-account`,
    feedback: `${BASE_URL}/user/feedback`,
  },
  gear: {
    commonEndPointForCategory : `${BASE_URL}/gear/category`,
    commonEndPointForCompany : `${BASE_URL}/gear/company`,
    getAllProduct : `${BASE_URL}/gear/product/all`,
    addProduct : `${BASE_URL}/gear/product`
  },
  bait : {
    genericBaitAPI : `${BASE_URL}/bait`,
  },
  species : {
    genericSpeciesAPI : `${BASE_URL}/species/all`,
    addEditSpecies : `${BASE_URL}/species`,
    getSpeciesDetails : `${BASE_URL}/species/getSpeciesDetails`,
  },
  waters : {
    genericWaterAPI : `${BASE_URL}/waters`,
    getAllWaterLIst : `${BASE_URL}/waters/all`
  },
  tfLiteModal : {
    tfModalFilesAPI : `${BASE_URL}/user/addTFModelFiles`,
  },
  unlabeledWater : {
    genericWaterAPI : `${BASE_URL}/user/UnlabeledWater`,
    deleteGenericWaterAPI : `${BASE_URL}/user/deleteUnlabeledWater`
  },
  tftModal : {
    genericTftAPI : `${BASE_URL}/user/getTFModelFilesDetails`,
  },
  contactus : {
    getContactUsAPI : `${BASE_URL}/contact/getContactUs`,
  },
  feedback : {
    getfeedbackAPI : `${BASE_URL}/user/feedback`,
  },
  dashboard : {
    getDashboardAPI : `${BASE_URL}/species/DashBoard`,
  },
  post : {
    genericPostAPI : `${BASE_URL}/userPost/report-post-list`,
    deletePostAPI : `${BASE_URL}/userPost/admin-post`
  },
  aboutApp : {
    genericAboutAppAPI: `${BASE_URL}/static/about-us`,
  },
  termAndCondition : {
    genericAboutAppAPI: `${BASE_URL}/static/terms`,
  },
  privacyPolicy : {
    genericAboutAppAPI: `${BASE_URL}/static/privacy`,
  },
  copyRightPolicy : {
    genericCopyRightAPI: `${BASE_URL}/static/copyright`,
  },
  rulesRegulations : {
    genericrulesRegulationsAPI: `${BASE_URL}/static/rules`,
  },
  restrictedFishAreas: {
    genericFishAreasAPI: `${BASE_URL}/restrictedFishArea`,
  }
};
