import React, { useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { GridColDef } from "@mui/x-data-grid";
import { useMutation } from "react-query";
import {  getAllContactUsList } from "../../../api";
import useDebounce from "../../../hooks/useDebounce";
import { charValidate } from "../../../components/utility";
import CustomTooltip from "../../../_metronic/layout/components/common/CustomTooltip";
import { KTCard, } from "../../../_metronic/helpers";
import MuiTable from "../../../components/table/MuiTable";

const ContactUs: React.FC = () => {
  const [tableOptions, setTableOptions] = useState({
    offset: 0,
    limit: 10,
    hasNextPage: true,
    hasPrevPage: true,
    totalRowCount: undefined,
    search: "",
  });

  const tableRef = useRef();
  const debounceSearchValue: string = useDebounce(tableOptions?.search, 500);

 

  const {
    mutateAsync: getContactListAPI,
    data: contactUsData,
    isLoading: isLoading,
  } = useMutation("contactUs-list", getAllContactUsList);

  useEffect(() => {
    getAllWaters(tableOptions);
  }, []);

  useEffect(() => {
    getAllWaters({
      ...tableOptions,
      search: debounceSearchValue ? debounceSearchValue : "",
    });
  }, [debounceSearchValue]);

  const getAllWaters = async (
    query:
      | {
          offset: number | string;
          limit: number;
          search: string;
        }
      | undefined
  ) => {
    let response = await getContactListAPI(query);
    if (response?.status) {
      setTableOptions((prevState: any) => {
        return {
          ...prevState,
          offset: response?.data?.offset,
          limit: response?.data?.limit,
          hasNextPage: response?.data?.hasNextPage,
          hasPrevPage: response?.data?.hasPrevPage,
          totalRowCount: response?.data?.totalDocs,
        };
      });
    }
  };
  
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "2-digit",
      hour12: true, // 12-hour format with AM/PM
    });
  };

  const columns: GridColDef[] = [
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 0.4,
      sortable: false,
      renderCell: (data) => (
        <CustomTooltip
          title={
            data?.row?.createdAt ? (
              <h6 style={{ color: "white" }}>{formatDate(data?.row?.createdAt)}</h6>
            ) : (
              "N/A"
            )
          }
        >
          <span>
            {data?.row?.createdAt ? formatDate(data?.row?.createdAt) : "N/A"}
          </span>
        </CustomTooltip>
      ),
    },
    {
      field: "subject",
      headerName: "Subject",
      flex: 0.4,
      sortable: false,
      renderCell: (data) => (
        <CustomTooltip
          title={
            data?.row?.subject ? (
              <h6 style={{ color: "white" }}>{data?.row?.subject}</h6>
            ) : (
              "N/A"
            )
          }
        >
          <span>
            {data?.row?.subject ? charValidate(data?.row?.subject, 30) : "N/A"}
          </span>
        </CustomTooltip>
      ),
    },
    {
      field: "message",
      headerName: "Message",
      flex: 0.4,
      sortable: false,
      renderCell: (data) => (
        <CustomTooltip
          title={
            data?.row?.message ? (
              <h6 style={{ color: "white" }}>{data?.row?.message}</h6>
            ) : (
              "N/A"
            )
          }
        >
          <span>
            {data?.row?.message ? charValidate(data?.row?.message, 30) : "N/A"}
          </span>
        </CustomTooltip>
      ),
    },
  ];

  const handlePagination = (values: { page: number; pageSize: number }) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    let query = {
      offset:
        pageSize == tableOptions?.limit ? (page === 0 ? 0 : `${page}` + 0) : 0,
      limit: pageSize ?? 10,
      search: debounceSearchValue ?? "",
    };
    getAllWaters(query);
  };


  
  return (
    <Card className="p-6">
      <>
        <KTCard>
          <MuiTable
            columns={columns}
            isAddEnable={false}
            data={contactUsData?.data?.docs ?? []}
            tableRef={tableRef}
            loading={isLoading}
            isBackEnable={false}
            isRowClickable={true}
            handlePagination={handlePagination}
            tableOptions={tableOptions}
          />
        </KTCard>
      </>
    </Card>
  );
};

export default ContactUs;