import { Box, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { ReactChild, ReactComponentElement, ReactElement } from "react";
import { Button } from "react-bootstrap";
import { KTIcon } from "../../_metronic/helpers";

export default function MuiTable({
  isAddEnable = true,
  isBackEnable = false,
  isRowClickable = false,
  addLabel = "",
  handleAddClick,
  handleBackClick,
  columns,
  data,
  tableRef,
  options,
  loading = false,
  children,
  handleCellClick,
  actionButtons,
  handlePagination,
  tableOptions,
  filters = null,
}: {
  isAddEnable?: boolean;
  isBackEnable?: boolean;
  isRowClickable?: boolean;
  addLabel?: string;
  handleAddClick?: () => void;
  handleBackClick?: () => void;
  handleCellClick?: (data: any) => any;
  columns?: GridColDef[];
  data?: any;
  tableRef?: any;
  options?: any;
  loading?: boolean;
  children?: ReactChild;
  actionButtons?: ReactComponentElement<any>;
  handlePagination?: (values: { page: number; pageSize: number }) => void;
  tableOptions?: any;
  filters?: ReactElement | null;
  getRowId?: (row: any) => any;  // Add this line
}) {
  return (
    <div className="w-100 d-flex flex-column">
      <div className="w-100 d-flex align-self-end align-items-center">
        
        {actionButtons}
        <div className={isBackEnable ? "back-button-class d-flex mb-5 w-100" : "table-add-btn"}>
            {isBackEnable && (
                <Button onClick={handleBackClick} className="d-flex align-items-center btn btn-secondary me-auto">
                  <KTIcon iconName="arrow-left" className="fs-1" />
                  Back
                </Button>
            )}
            {isAddEnable && (
            <Button onClick={handleAddClick} className="d-flex align-items-center">
              <KTIcon iconName="plus" className="fs-1" />
              {addLabel}
            </Button>          
            )}
        </div>
      </div>
      {/* {isAddEnable && (
        <div
        className="w-100 mb-7"
        style={{
          height: 1,
          borderBottom:
            "1px dashed var(--bs-app-sidebar-light-separator-color)",
        }}
      ></div>
      )} */}
      <div>{children}</div>
      <Box
        sx={{
          width: "100%",
        }}
      >
        {filters}
        <DataGrid
          rows={data ?? []}
          columns={columns ?? []}
          pageSizeOptions={[10, 25, 50, 100]}
          ref={tableRef ?? null}
          pagination={true}
          getRowId={(data) => data?._id ?? data?.holeNumber}
          paginationMode="server"
          loading={loading}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: tableOptions?.limit,
                page: tableOptions?.offset,
              },
            },
          }}
          disableColumnFilter={true}
          disableColumnMenu={true}
          disableColumnSelector={true}
          onCellClick={handleCellClick}
          onPaginationModelChange={handlePagination}
          rowCount={tableOptions?.totalRowCount}
          slots={{
            loadingOverlay: () => (
              <Typography
                variant="h4"
                fontWeight={500}
                fontSize={13}
                sx={noDataLoadingLabelStyle}
              >
                Loading...
              </Typography>
            ),
            noRowsOverlay: () => (
              <Typography
                variant="h4"
                fontWeight={500}
                fontSize={13}
                sx={noDataLoadingLabelStyle}
              >
                No Data Found
              </Typography>
            ),
          }}
          sx={{
            width: 1,
            "& .MuiDataGrid-overlayWrapper": {
              minHeight: "150px !important",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              textTransform: "uppercase !important",
              fontWeight: "400 !important",
            },
            // disable cell selection style
            ".MuiDataGrid-cell:focus": {
              outline: "none",
            },
            // pointer cursor on ALL rows
            "& .MuiDataGrid-row:hover": {
              cursor: isRowClickable ? "pointer" : "initial",
            },
          }}
          {...options}
        />
      </Box>
    </div>
  );
}

const noDataLoadingLabelStyle = {
  textAlign: "center",
  position: "relative",
  top: "30%",
};
