import React, { useState } from "react";
import { Box } from "@mui/material";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import CloseIcon from "@mui/icons-material/Close";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import { addEditBaits, uploadPicture } from "../../api";
import { KTIcon } from "../../_metronic/helpers";
import { APP_URLs } from "../../constants/appURLs";
import { charValidate } from "../../components/utility";
import { IBaits } from "../../constants/types";

export type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  editData: IBaits;
};

const AddEditBaits = ({ show, handleClose, editData }: Props) => {
  let imgUrl = editData?.image
    ? editData?.image[0]
      ? editData?.image[0]
      : ""
    : "";

  const [baitsImg, setCompanyImg] = useState("");
  const [imgUploading, setImgUploading] = useState<boolean>(false);

  const [imgFileName, setImgFileName] = useState(
    imgUrl ? imgUrl?.split("/img/")[1] : ""
  );

  const companyValidationSchema = Yup.object().shape({
    name: Yup.string().trim().required("Name is required"),
    image: Yup.string().required("Image is required"),
    description: Yup.string().required("Description is required"),
    subTitle: Yup.string().required("Subtitle is required"),
  });

  const { mutateAsync: addEditBaitsAPI, isLoading: isLoading } = useMutation(
    "add-product",
    addEditBaits
  );

  const formik = useFormik({
    initialValues: {
      name: editData ? editData?.name : "",
      image: editData ? editData?.image : "",
      description: editData?.description ?? "",
      subTitle: editData?.subtitle ?? "",
    },

    validationSchema: companyValidationSchema,
    onSubmit: async (values, { setFieldError }) => {
      if (!baitsImg && !editData?.image) {
        setFieldError("image", "Image is required");
        return;
      }
      let bodyParams = {
        id: editData ? editData?._id : undefined,
        name: values?.name ? values?.name?.replace(/^\s+|\s+$/g, "") : "",
        image: baitsImg || (editData && editData.image) || null,
        subtitle: values?.subTitle
          ? values?.subTitle?.replace(/^\s+|\s+$/g, "")
          : "",
        description: values?.description ? values?.description : "",
      };
      let response = await addEditBaitsAPI(bodyParams);
      if (response?.status) {
        snackActions.success(
          response?.message ?? "Company Added Successfully!"
        );
        formik.resetForm();
        setImgFileName("");
        setCompanyImg("");
        handleClose(true);
      } else {
        snackActions.error(response?.message ?? "Something went wrong!");
      }
    },
  });

  const handleChangeImageUpload = async (e: any) => {
    let file = e?.target?.files[0];
    let formData = new FormData();
    setImgFileName(file?.name ?? "");
    formData.append("photo", file);
    if (file) {
      setImgUploading(true)
      let response = await uploadPicture(formData);
      if (response?.status) {
        setCompanyImg(response?.data?.images);
        formik.setFieldValue("image", response?.data?.images);
        setImgUploading(false);
      } else {
        snackActions.error(
          response?.message ?? "Issue in image upload, try again latter!"
        );
        setImgUploading(false);
      }
    }
  };

  const removeImg = () => {
    formik.setFieldValue("image", "");
    formik.setFieldError("image", undefined);
    setCompanyImg("");
    if (editData) {
      editData.image = "";
    }
    setImgFileName("");
  };

  const handleCancel = () => {
    formik.resetForm();
    setImgFileName("");
    setCompanyImg("");
    handleClose(false);
  };

  return (
    <Box>
      <Modal
        id="kt_modal_create_app"
        tabIndex={-1}
        aria-hidden="true"
        dialogClassName="modal-dialog modal-dialog-centered mw-700px"
        show={show}
        backdrop="static"
      >
        <div className="modal-header">
          <h2> {editData ? "Edit" : "Add"} Bait</h2>
          {/* begin::Close */}
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              setImgFileName("");
              setCompanyImg("");
              handleClose(false);
            }}
          >
            <KTIcon className="fs-1" iconName="cross" />
          </div>
          {/* end::Close */}
        </div>

        <div className="p-6" style={{ overflowY: "auto" }}>
          <form onSubmit={formik.handleSubmit} noValidate className="form">
            <Row className="mb-6">
              <Col xs={6}>
                <label className="form-label fw-bold">
                  Name<span className="red-asterisk">*</span>
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Name"
                  aria-label="Name"
                  {...formik.getFieldProps("name")}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.name}</div>
                  </div>
                )}
              </Col>

              <Col xs={6}>
                <label className="form-label fw-bold">
                  Enter Subtitle<span className="red-asterisk">*</span>
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-5 mb-lg-0"
                  placeholder="Subtitle"
                  aria-label="Name"
                  {...formik.getFieldProps("subTitle")}
                />
                {formik.touched.subTitle && formik.errors.subTitle && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.subTitle}
                    </div>
                  </div>
                )}
              </Col>
            </Row>
            <div className="mb-6">
              <label className="form-label fw-bold">
                Enter Description<span className="red-asterisk">*</span>
              </label>
              <ReactQuill
                theme="snow"
                placeholder="Write something..."
                value={formik.values.description}
                modules={AddEditBaits.modules}
                formats={AddEditBaits.formats}
                onChange={(content) =>
                  formik.setFieldValue("description", content)
                }
              />
              {formik.touched.description && formik.errors.description && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.description}
                  </div>
                </div>
              )}
            </div>
            <div>
              <label className="form-label fw-bold">
                Image upload<span className="red-asterisk">*</span>
              </label>
              <div>
                <label
                  htmlFor="baits-img"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 cursor-pointer"
                >
                  {imgFileName ? charValidate(imgFileName, 25) : "Choose File"}
                </label>
                <input
                  id="baits-img"
                  type="file"
                  accept="image/*"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Upload Image"
                  aria-label="Upload Image"
                  onChange={handleChangeImageUpload}
                  style={{
                    display: "none",
                  }}
                />
                {formik.touched.image && formik.errors.image && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.image}</div>
                  </div>
                )}
              </div>
            </div>
            {imgUploading && (
              <div className="d-flex justify-content-center m-5">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}

            {(baitsImg || editData?.image) && (
              <div
                style={{
                  textAlign: "center",
                  marginTop: "1rem",
                  position: "relative",
                }}
              >
                <img
                  src={`${APP_URLs.awsS3ImageURL}${
                    baitsImg || editData?.image
                  }`}
                  style={{
                    maxWidth: "200px",
                    margin: "0 auto",
                    padding: "1rem",
                    border: "1px solid #eeeeee",
                  }}
                  alt="Preview"
                />
                <CloseIcon className="close-icon" onClick={removeImg} />
              </div>
            )}
            <div className="w-100 d-flex align-items-center justify-content-end mt-5">
              <Button onClick={handleCancel} size="lg" variant="secondary" disabled={imgUploading}>
                Cancel
              </Button>
              <div className="w-15px"></div>
              <Button type="submit" size="lg" variant="primary" disabled={imgUploading}>
                {isLoading ? "Loading..." : "Submit"}
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </Box>
  );
};

export default AddEditBaits;

AddEditBaits.modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [{ color: [] }],
  ],
};
AddEditBaits.formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
];
