import { Button, Col, Row } from "react-bootstrap";
import "react-quill/dist/quill.snow.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  prevStep,
  resetStep,
  setSpeciesFormData,
} from "../../store/slice/form/speciesForm";
import { useMutation } from "react-query";
import { addEditSpecies } from "../../api";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import ReactQuill from "react-quill";

const Step_2 = ({handleClose, editData} : any) => {
  
  const { mutateAsync: addEditSpeciesAPI, isLoading: isLoading } = useMutation(
    "add-species",
    addEditSpecies
  );
  const Step_2_validation_schema = Yup.object().shape({
    foodValue: Yup.string().trim().required("Food value is required"),
    season: Yup.string().trim().required("Season is required"),
    bagLimit: Yup.string().trim().required("Bag limit is required"),
    regulations: Yup.string().trim().required("Regulations is required"),
    gearAllowed: Yup.string().trim().required("Gear allowed is required"),
    disclaimer: Yup.string().trim().required("Disclaimer is required"),
    howToCatch: Yup.string().required("How to catch is required"),
    commonNames: Yup.string().required("Common names is required"),
    coloration: Yup.string().required("Avg weight is required"),
  });
  const dispatch = useDispatch();

  const formDatas = useSelector((state: any) => state?.species?.formData);  

  const formik = useFormik({
    initialValues: {
      foodValue: formDatas?.foodValue || "",
      season: formDatas?.season || "",
      bagLimit: formDatas?.bagLimit || "",
      regulations: formDatas?.regulations || "",
      gearAllowed: formDatas?.gearAllowed || "",
      disclaimer: formDatas?.disclaimer || "",
      howToCatch: formDatas?.howToCatch || "",
      commonNames: formDatas?.commonNames || "",
      coloration: formDatas?.coloration || "",
    },
    validationSchema: Step_2_validation_schema,
    onSubmit: async (values) => {
      dispatch(setSpeciesFormData(values));
      const bodyParams = {
        id : editData?._id ? editData?._id : undefined,
        name: formDatas?.name ?? "",
        image: formDatas?.image ?? "",
        description: formDatas?.description ?? "",
        scientificName: formDatas?.scientificName ?? "",
        lengthAvg: formDatas?.lengthAvg ?? "",
        lengthMax: formDatas?.lengthMax ?? "",
        weightMax: formDatas?.weightMax ?? "",
        weightAvg: formDatas?.weightAvg ?? "",
        lengthMaxMeasure: formDatas?.lengthMaxMeasure ?? "",
        lengthAvgMeasure: formDatas?.lengthAvgMeasure ?? "",
        weightMaxMeasure: formDatas?.weightMaxMeasure ?? "",
        weightAvgMeasure: formDatas?.weightAvgMeasure ?? "",
        foodValue: values?.foodValue ?? "",
        coloration: values?.coloration ?? "",
        commonNames: values?.commonNames ?? "",
        howToCatch: values?.howToCatch ?? "",
        season: values?.season ?? "",
        bagLimit: values?.bagLimit ?? "",
        regulations: values?.regulations ?? "",
        disclaimer: values?.disclaimer ?? "",
        gearAllowed: values?.gearAllowed ?? ""
      };
      let response = await addEditSpeciesAPI(bodyParams);
      if (response?.status) {
        snackActions.success(
          response?.message ?? "Form submitted successfully!"
        );
        handleClose(true);
        dispatch(resetStep());
      } else {
        snackActions.error(response?.message ?? "Something went wrong!");
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} noValidate className="form">
      <div className="p-7">
        <Row>
          <Col md={6} className="mb-4">
            <label className="form-label fw-bold">
              Enter Food value<span className="red-asterisk">*</span>
            </label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid"
              placeholder="Food value"
              aria-label="Name"
              {...formik.getFieldProps("foodValue")}
            />
            {formik.touched.foodValue &&
              formik.errors.foodValue &&
              typeof formik.errors.foodValue === "string" && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.foodValue}</div>
                </div>
              )}
          </Col>
          <Col md={6} className="mb-4">
            <label className="form-label fw-bold">
              Enter Season (open or limited)
              <span className="red-asterisk">*</span>
            </label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid"
              placeholder="Season (open or limited)"
              aria-label="Name"
              {...formik.getFieldProps("season")}
            />
            {formik.touched.season &&
              formik.errors.season &&
              typeof formik.errors.season === "string" && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.season}</div>
                </div>
              )}
          </Col>
          <Col md={6} className="mb-4">
            <label className="form-label fw-bold">
              Enter Bag limit<span className="red-asterisk">*</span>
            </label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid"
              placeholder="Bag limit"
              aria-label="Name"
              {...formik.getFieldProps("bagLimit")}
            />
            {formik.touched.bagLimit &&
              formik.errors.bagLimit &&
              typeof formik.errors.bagLimit === "string" && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.bagLimit}</div>
                </div>
              )}
          </Col>
          <Col md={6} className="mb-4">
            <label className="form-label fw-bold">
              Enter Coloration<span className="red-asterisk">*</span>
            </label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid"
              placeholder="Coloration"
              aria-label="Name"
              {...formik.getFieldProps("coloration")}
            />
            {formik.touched.coloration &&
              formik.errors.coloration &&
              typeof formik.errors.coloration === "string" && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.coloration}
                  </div>
                </div>
              )}
          </Col>
          <Col md={6} className="mb-4">
            <label className="form-label fw-bold">
              Enter Common names<span className="red-asterisk">*</span>
            </label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid"
              placeholder="Common names"
              aria-label="Name"
              {...formik.getFieldProps("commonNames")}
            />
            {formik.touched.commonNames &&
              formik.errors.commonNames &&
              typeof formik.errors.commonNames === "string" && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.commonNames}
                  </div>
                </div>
              )}
          </Col>
          <Col md={6} className="mb-4">
            <label className="form-label fw-bold">
              Enter How to catch<span className="red-asterisk">*</span>
            </label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid"
              placeholder="How to catch"
              aria-label="Name"
              {...formik.getFieldProps("howToCatch")}
            />
            {formik.touched.howToCatch &&
              formik.errors.howToCatch &&
              typeof formik.errors.howToCatch === "string" && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.howToCatch}
                  </div>
                </div>
              )}
          </Col>
          <Col md={12} className="mb-4">
            <label className="form-label fw-bold">
              Enter Regulations<span className="red-asterisk">*</span>
            </label>
            <ReactQuill
              theme="snow"
              placeholder="Write something..."
              value={formik.values.regulations}
              modules={Step_2.modules}
              formats={Step_2.formats}
              onChange={(content) =>
                formik.setFieldValue("regulations", content)
              }
            />
            {formik.touched.regulations &&
              formik.errors.regulations &&
              typeof formik.errors.regulations === "string" && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.regulations}
                  </div>
                </div>
              )}
          </Col>
          <Col md={12} className="mb-4">
            <label className="form-label fw-bold">
              Gear Allowed<span className="red-asterisk">*</span>
            </label>
            <ReactQuill
              theme="snow"
              placeholder="Write something..."
              value={formik.values.gearAllowed}
              modules={Step_2.modules}
              formats={Step_2.formats}
              onChange={(content) =>
                formik.setFieldValue("gearAllowed", content)
              }
            />
            {formik.touched.gearAllowed &&
              formik.errors.gearAllowed &&
              typeof formik.errors.gearAllowed === "string" && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.gearAllowed}
                  </div>
                </div>
              )}
          </Col>
          <Col md={12} className="mb-4">
            <label className="form-label fw-bold">
            Disclaimer<span className="red-asterisk">*</span>
            </label>
            <ReactQuill
              theme="snow"
              placeholder="Write something..."
              value={formik.values.disclaimer}
              modules={Step_2.modules}
              formats={Step_2.formats}
              onChange={(content) =>
                formik.setFieldValue("disclaimer", content)
              }
            />
            {formik.touched.disclaimer &&
              formik.errors.disclaimer &&
              typeof formik.errors.disclaimer === "string" && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.disclaimer}
                  </div>
                </div>
              )}
          </Col>
        </Row>
      </div>
      <div
        className="modal-footer"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Button
          onClick={() => dispatch(prevStep())}
          size="lg"
          variant="secondary"
        >
          Back
        </Button>
        <Button className="ms-3" type="submit" size="lg" variant="primary">
          {isLoading ? "Loading..." : "Submit"}
        </Button>
      </div>
    </form>
  );
};

export default Step_2;


Step_2.modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [{ color: [] }],
  ],
};
Step_2.formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
];