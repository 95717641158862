import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Tooltip } from "@mui/material";
import { Card } from "react-bootstrap";
import { GridColDef } from "@mui/x-data-grid";
import { useMutation } from "react-query";
import useDebounce from "../../../hooks/useDebounce";
import { getFeedbackList } from "../../../api";
import CustomTooltip from "../../../_metronic/layout/components/common/CustomTooltip";
import { KTCard } from "../../../_metronic/helpers";
import MuiTable from "../../../components/table/MuiTable";
import InfoIcon from "@mui/icons-material/Info";
import { charValidate } from "../../../components/utility";
import FeedbackInfoPopUp from "./FeedbackInfoPopUp";

const Feedback: React.FC = () => {
  const [tableOptions, setTableOptions] = useState({
    offset: 0,
    limit: 10,
    hasNextPage: true,
    hasPrevPage: true,
    totalRowCount: undefined,
    search: "",
  });

  const tableRef = useRef();
  const debounceSearchValue: string = useDebounce(tableOptions?.search, 500);

  const [showInfoModal, setShowInfoModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();

  const {
    mutateAsync: getFeedbackListAPI,
    data: feedbackData,
    isLoading: isLoading,
  } = useMutation("feedback-list", getFeedbackList);

  useEffect(() => {
    getAllFeedbacks(tableOptions);
  }, []);

  useEffect(() => {
    getAllFeedbacks({
      ...tableOptions,
      search: debounceSearchValue ? debounceSearchValue : "",
    });
  }, [debounceSearchValue]);

  const getAllFeedbacks = async (
    query:
      | {
          offset: number | string;
          limit: number;
          search: string;
        }
      | undefined
  ) => {
    let response = await getFeedbackListAPI(query);
    if (response?.status) {
      setTableOptions((prevState: any) => {
        return {
          ...prevState,
          offset: response?.data?.offset,
          limit: response?.data?.limit,
          hasNextPage: response?.data?.hasNextPage,
          hasPrevPage: response?.data?.hasPrevPage,
          totalRowCount: response?.data?.totalDocs,
        };
      });
    }
  };

  const columns: GridColDef[] = [
    {
      field: "UserId",
      headerName: "First Name",
      flex: 0.4,
      sortable: false,
      renderCell: (data) => data?.row?.UserId?.firstName ?? "N/A",
    },
    {
      field: "UserIdLastName",
      headerName: "Last Name",
      flex: 0.4,
      sortable: false,
      renderCell: (data) => data?.row?.UserId.lastName ?? "N/A",
    },
    {
      field: "feedbackType",
      headerName: "Type",
      flex: 0.4,
      sortable: false,
      renderCell: (data) => data?.row?.feedbackType ?? "N/A",
    },
    {
      field: "feedbackContent",
      headerName: "Description",
      flex: 0.4,
      sortable: false,
      renderCell: (data) => (
        <CustomTooltip
          title={
            data?.row ? (
              <h6 style={{ color: "white" }}>{data?.row?.feedbackContent}</h6>
            ) : (
              "N/A"
            )
          }
        >
          <span>
            {data?.row?.feedbackContent
              ? charValidate(data?.row?.feedbackContent, 30)
              : "N/A"}
          </span>
        </CustomTooltip>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.2,
      sortable: false,
      headerAlign: "center",
      align: "right",
      renderCell: (data) => (
        <Box display="flex" alignItems="center">
          <Tooltip title="info">
            <InfoIcon
              onClick={() => {
                setSelectedRow(data.row);
                setShowInfoModal(true);
              }}
              sx={{ fontSize: 22, cursor: "pointer" }}
              color="info"
            />
          </Tooltip>
        </Box>
      ),
    },
  ];

  const handlePagination = (values: { page: number; pageSize: number }) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    let query = {
      offset:
        pageSize == tableOptions?.limit ? (page === 0 ? 0 : `${page}` + 0) : 0,
      limit: pageSize ?? 10,
      search: debounceSearchValue ?? "",
    };
    getAllFeedbacks(query);
  };

  const handleChangeFilters = (fieldName: string, value: string) => {
    setTableOptions((prevState: any) => {
      return {
        ...prevState,
        [fieldName]: value,
      };
    });
  };
  const handleModalClose = (isSubmit : boolean) => {
    if(isSubmit){
      getAllFeedbacks(tableOptions);
    }
    setSelectedRow("");
    setShowInfoModal(false)
  };

  return (
    <Card className="p-6">
      <>
        <KTCard>
          <MuiTable
            columns={columns}
            isAddEnable={false}
            data={feedbackData?.data?.docs ?? []}
            tableRef={tableRef}
            loading={isLoading}
            isBackEnable={false}
            isRowClickable={true}
            handlePagination={handlePagination}
            tableOptions={tableOptions}
            filters={
              <Grid container spacing={2} pb={3}>
                <Grid item lg={3} md={4} xs={12}>
                  <Box>
                    <label
                      className="form-label fw-bold"
                      style={{ marginLeft: 5 }}
                    >
                      Search By Name
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Search by name"
                      aria-label="Search"
                      onChange={(e: any) =>
                        handleChangeFilters("search", e?.target?.value)
                      }
                      value={tableOptions?.search}
                    />
                  </Box>
                </Grid>
              </Grid>
            }
          />
          {showInfoModal && (
            <FeedbackInfoPopUp
              show={showInfoModal}
              handleClose={handleModalClose}
              editData={selectedRow}
            />
          )}
        </KTCard>
      </>
    </Card>
  );
};

export default Feedback;
