import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  step: 0,
  formData: {
    name: "",
    radius: "",
    latitude: "",
    longitude: "",
    fishes: [],
    species: "",
    speciesId: "",
    description: ""
  },
};

const restrictedFishAreaFormSlice = createSlice({
  name: "restrictedFishAreaForm",
  initialState,
  reducers: {
    nextStep(state) {
      state.step += 1;
    },
    prevStep(state) {
      state.step -= 1;
    },
    setRestrictedFishAreaFormData(state, action) {
      state.formData = { ...state.formData, ...action.payload };
    },
    resetStep(state) {
      state.step = 0;
    },
    resetFormData(state) {
      state.formData = initialState.formData;
    }
  },
});

export const { nextStep, prevStep, setRestrictedFishAreaFormData, resetStep, resetFormData } =
  restrictedFishAreaFormSlice.actions;

export default restrictedFishAreaFormSlice.reducer;
