/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from "react";
import { useDispatch } from "react-redux";
// import AddEditAdmin from "../../../../components/app-user/AddEditAdmin";
import { charValidate } from "../../../../components/utility";
import { useUserInfo } from "../../../../hooks/useUserInfo";
import { userLogout } from "../../../../store/slice/auth/authActions";
import { toAbsoluteUrl } from "../../../helpers";
import ConfirmEventPopup from "../../../layout/components/common/ConfirmEventPopup";
import CustomTooltip from "../../../layout/components/common/CustomTooltip";
import ChangePassword from "../../../../components/app-user/ChangePassword";

const HeaderUserMenu: FC = () => {
  const dispatch = useDispatch();
  const userInfo = useUserInfo();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showSignOutConfirmModal, setShowSignOutConfirmModal] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);

  const logoutUser = async () => {
    setShowLoading(true);
    localStorage.clear();
    window.location.reload();
    // await dispatch(userLogout());
    setShowLoading(false);
  };

  const handleModalClose = (isSubmit: boolean) => {
    setShowEditModal(false);
    if (isSubmit) {
      // delay for refresh the page
      setTimeout(() => {
        window.location.reload();
      }, 300);
    }
  };

  const handleChangePasswordClose = (isSubmit: boolean) => {
    setShowChangePassword(false);
  };

  const imageStyle = {
    filter: "brightness(30%) contrast(220%)", // Default values
  };

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          {/* <div className="symbol symbol-50px me-5">
            <img
              alt="Logo"
              src={toAbsoluteUrl("/media/app-logo/logo.png")}
              style={imageStyle}
              className="h-20px w-50px"
            />
          </div> */}

          <div
            style={{
              background: "#009ABC",
              borderRadius: "50%",
              padding: "10px",
              color: "#eee",
              marginRight: "10px",
              fontSize: "20px",
              height: "48px",
              width: "48px",
              textAlign: "center",
              fontWeight:"500"
            }}
          >
            {userInfo?.firstName?.charAt(0)?.toUpperCase()}{userInfo?.lastName?.charAt(0)?.toUpperCase()}
          </div>

          <div className="d-flex flex-column">
            <div className="fw-bolder d-flex align-items-center fs-5">
              <CustomTooltip
                title={
                  userInfo?.firstName && userInfo?.lastName ? (
                    <h6 style={{ color: "white" }}>
                      {userInfo?.firstName} {userInfo?.lastName}
                    </h6>
                  ) : (
                    "N/A"
                  )
                }
              >
                <span>
                  {userInfo?.firstName && userInfo?.lastName
                    ? charValidate(
                        `${userInfo?.firstName} ${userInfo?.lastName}`,
                        20
                      )
                    : "N/A"}
                </span>
              </CustomTooltip>
              {/* <span className="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">
                Pro
              </span> */}
            </div>
            <a href="#" className="fw-bold text-muted text-hover-primary fs-7">
              <CustomTooltip
                title={
                  userInfo?.email ? (
                    <h6 style={{ color: "white" }}>{userInfo?.email}</h6>
                  ) : (
                    "N/A"
                  )
                }
              >
                <span>
                  {userInfo?.email ? charValidate(userInfo?.email, 20) : "N/A"}
                </span>
              </CustomTooltip>
            </a>
          </div>
        </div>
      </div>

      <div className="separator my-2"></div>

      {/* <div className="menu-item px-5">
        <Link to={"/crafted/pages/profile"} className="menu-link px-5">
          My Profile
        </Link>
      </div> */}

      {/* <div className="menu-item px-5">
        <a href="#" className="menu-link px-5">
          <span className="menu-text">My Projects</span>
          <span className="menu-badge">
            <span className="badge badge-light-danger badge-circle fw-bolder fs-7">
              3
            </span>
          </span>
        </a>
      </div> */}

      {/* <div
        className="menu-item px-5"
        data-kt-menu-trigger="hover"
        data-kt-menu-placement="left-start"
        data-kt-menu-flip="bottom"
      >
        <a href="#" className="menu-link px-5">
          <span className="menu-title">My Subscription</span>
          <span className="menu-arrow"></span>
        </a>

        <div className="menu-sub menu-sub-dropdown w-175px py-4">
          <div className="menu-item px-3">
            <a href="#" className="menu-link px-5">
              Referrals
            </a>
          </div>

          <div className="menu-item px-3">
            <a href="#" className="menu-link px-5">
              Billing
            </a>
          </div>

          <div className="menu-item px-3">
            <a href="#" className="menu-link px-5">
              Payments
            </a>
          </div>

          <div className="menu-item px-3">
            <a href="#" className="menu-link d-flex flex-stack px-5">
              Statements
              <i
                className="fas fa-exclamation-circle ms-2 fs-7"
                data-bs-toggle="tooltip"
                title="View your statements"
              ></i>
            </a>
          </div>

          <div className="separator my-2"></div>

          <div className="menu-item px-3">
            <div className="menu-content px-3">
              <label className="form-check form-switch form-check-custom form-check-solid">
                <input
                  className="form-check-input w-30px h-20px"
                  type="checkbox"
                  value="1"
                  defaultChecked={true}
                  name="notifications"
                />
                <span className="form-check-label text-muted fs-7">
                  Notifications
                </span>
              </label>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="menu-item px-5">
        <a href="#" className="menu-link px-5">
          My Statements
        </a>
      </div> */}

      <div className="separator my-2"></div>

      {/* <Languages /> */}
{/* 
      <div onClick={() => setShowEditModal(true)} className="menu-item px-5">
        <a className="menu-link px-5">Edit Profile</a>
      </div> */}

      <div
        onClick={() => setShowChangePassword(true)}
        className="menu-item px-5"
      >
        <a className="menu-link px-5">Change password</a>
      </div>

      <div className="menu-item px-5">
        <a
          onClick={() => setShowSignOutConfirmModal(true)}
          className="menu-link px-5"
        >
          Sign Out
        </a>
      </div>
      {/* {showEditModal && (
        <AddEditAdmin
          editData={userInfo}
          show={showEditModal}
          handleClose={handleModalClose}
          title="Edit Details"
          isForEditProfile={true}
        />
      )} */}
      {showChangePassword && (
        <ChangePassword
          show={showChangePassword}
          handleClose={handleChangePasswordClose}
          title="Change password"
        />
      )}
      {showSignOutConfirmModal && (
        <ConfirmEventPopup
          showPopup={showSignOutConfirmModal}
          handleClose={() => setShowSignOutConfirmModal(false)}
          handleConfirmed={logoutUser}
          loading={showLoading}
          title={"Sign Out"}
          message={"Are you sure, want to sign out?"}
        />
      )}
    </div>
  );
};

export { HeaderUserMenu };
