import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Tooltip } from "@mui/material";
import { Card } from "react-bootstrap";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import { GridColDef } from "@mui/x-data-grid";
import { useMutation } from "react-query";
import useDebounce from "../../../hooks/useDebounce";
import { deleteUsers, getAllUserList } from "../../../api";
import { KTCard } from "../../../_metronic/helpers";
import MuiTable from "../../../components/table/MuiTable";
import { snackActions } from "../../../helpers/SnackUtilsConfigurator";
import ConfirmEventPopup from "../../../_metronic/layout/components/common/ConfirmEventPopup";

const Users: React.FC = () => {
  const [tableOptions, setTableOptions] = useState({
    offset: 0,
    limit: 10,
    hasNextPage: true,
    hasPrevPage: true,
    totalRowCount: undefined,
    search: "",
  });

  const tableRef = useRef();
  const debounceSearchValue: string = useDebounce(tableOptions?.search, 500);

  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();

  const {
    mutateAsync: getAllUserListAPI,
    data: usersData,
    isLoading: isLoading,
  } = useMutation("user-list", getAllUserList);

  const { mutateAsync: deleteUsersAPI, isLoading: isDeleting } = useMutation(
    "delete-user",
    deleteUsers
  );

  useEffect(() => {
    getAllWaters(tableOptions);
  }, []);

  useEffect(() => {
    getAllWaters({
      ...tableOptions,
      search: debounceSearchValue ? debounceSearchValue : "",
    });
  }, [debounceSearchValue]);

  const getAllWaters = async (
    query:
      | {
          offset: number | string;
          limit: number;
          search: string;
        }
      | undefined
  ) => {
    let response = await getAllUserListAPI(query);
    if (response?.status) {
      setTableOptions((prevState: any) => {
        return {
          ...prevState,
          offset: response?.data?.offset,
          limit: response?.data?.limit,
          hasNextPage: response?.data?.hasNextPage,
          hasPrevPage: response?.data?.hasPrevPage,
          totalRowCount: response?.data?.totalDocs,
        };
      });
    }
  };

  const columns: GridColDef[] = [
    {
      field: "firstName",
      headerName: "First Name",
      flex: 0.4,
      sortable: false,
      renderCell: (data) => data?.row?.firstName ?? "N/A",
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 0.4,
      sortable: false,
      renderCell: (data) => data?.row?.lastName ?? "N/A",
    },
    {
      field: "username",
      headerName: "User Name",
      flex: 0.4,
      sortable: false,
      renderCell: (data) => data?.row?.username ?? "N/A",
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.2,
      sortable: false,
      headerAlign: "center",
      align: "right",
      renderCell: (data) => (
        <Box display="flex" alignItems="center">
          <Tooltip title="Delete">
            <DeleteSweepIcon
              onClick={() => {
                setSelectedRow(data.row);
                setShowDeleteConfirmModal(true);
              }}
              sx={{ fontSize: 22, ml: 1.7, cursor: "pointer" }}
              color="error"
            />
          </Tooltip>
        </Box>
      ),
    },
  ];

  const handlePagination = (values: { page: number; pageSize: number }) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    let query = {
      offset:
        pageSize == tableOptions?.limit ? (page === 0 ? 0 : `${page}` + 0) : 0,
      limit: pageSize ?? 10,
      search: debounceSearchValue ?? "",
    };
    getAllWaters(query);
  };

  const handleChangeFilters = (fieldName: string, value: string) => {
    setTableOptions((prevState: any) => {
      return {
        ...prevState,
        [fieldName]: value,
      };
    });
  };

  const deleteUserFunc = async () => {
    let response = await deleteUsersAPI({ id: selectedRow?._id });
    if (response?.status) {
      snackActions.success(response?.message);
      handleCloseDelete(true);
      getAllWaters(tableOptions);
    } else {
      snackActions.error(response?.message);
    }
  };

  const handleCloseDelete = (isSubmit : boolean) => {
    if(isSubmit){
      getAllWaters(tableOptions);
    }
    setShowDeleteConfirmModal(false);
    setSelectedRow("");
  };

  return (
    <Card className="p-6">
      <>
        <KTCard>
          <MuiTable
            columns={columns}
            isAddEnable={false}
            data={usersData?.data?.docs ?? []}
            tableRef={tableRef}
            loading={isLoading}
            isBackEnable={false}
            isRowClickable={true}
            handlePagination={handlePagination}
            tableOptions={tableOptions}
            filters={
              <Grid container spacing={2} pb={3}>
                <Grid item lg={3} md={4} xs={12}>
                  <Box>
                    <label
                      className="form-label fw-bold"
                      style={{ marginLeft: 5 }}
                    >
                      Search By Name
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Search by name"
                      aria-label="Search"
                      onChange={(e: any) =>
                        handleChangeFilters("search", e?.target?.value)
                      }
                      value={tableOptions?.search}
                    />
                  </Box>
                </Grid>
              </Grid>
            }
          />
          {showDeleteConfirmModal && (
            <ConfirmEventPopup
              showPopup={showDeleteConfirmModal}
              handleClose={handleCloseDelete}
              handleConfirmed={deleteUserFunc}
              loading={isDeleting}
              title={"Delete User"}
              message={"Are you sure, want to delete this user?"}
            />
          )}
        </KTCard>
      </>
    </Card>
  );
};

export default Users;
