import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Tooltip } from "@mui/material";
import { Card } from "react-bootstrap";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import { GridColDef } from "@mui/x-data-grid";
import { useMutation } from "react-query";
import errorFishImageJpg from '../../../_metronic/assets/errorFishImage.jpg'
import useDebounce from "../../../hooks/useDebounce";
import { deleteAdminPost, getPostList } from "../../../api";
import { APP_URLs } from "../../../constants/appURLs";
import { KTCard } from "../../../_metronic/helpers";
import MuiTable from "../../../components/table/MuiTable";
import ConfirmEventPopup from "../../../_metronic/layout/components/common/ConfirmEventPopup";
import { snackActions } from "../../../helpers/SnackUtilsConfigurator";

const Post: React.FC = () => {
  const [tableOptions, setTableOptions] = useState({
    offset: 0,
    limit: 10,
    hasNextPage: true,
    hasPrevPage: true,
    totalRowCount: undefined,
    search: "",
  });

  const tableRef = useRef();
  const debounceSearchValue: string = useDebounce(tableOptions?.search, 500);
  
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();

  const { mutateAsync: deleteAdminPostAPI, isLoading: isDeleting } = useMutation(
    "delete-admin-post",
    deleteAdminPost
  );

  const {
    mutateAsync: getPostListAPI,
    data: postsData,
    isLoading: isLoading,
  } = useMutation("post-list", getPostList);

  useEffect(() => {
    getAllPosts(tableOptions);
  }, []);

  useEffect(() => {
    getAllPosts({
      ...tableOptions,
      search: debounceSearchValue ? debounceSearchValue : "",
    });
  }, [debounceSearchValue]);

  const getAllPosts = async (
    query:
      | {
          offset: number | string;
          limit: number;
          search: string;
        }
      | undefined
  ) => {
    let response = await getPostListAPI(query);
    if (response?.status) {
      setTableOptions((prevState: any) => {
        return {
          ...prevState,
          offset: response?.data?.offset,
          limit: response?.data?.limit,
          hasNextPage: response?.data?.hasNextPage,
          hasPrevPage: response?.data?.hasPrevPage,
          totalRowCount: response?.data?.totalDocs,
        };
      });
    }
  };
  
  const handleImageError = (event : any) => {
    event.target.src = errorFishImageJpg;
  };

  const columns: GridColDef[] = [
    {
      field: "Images",
      headerName: "Image",
      flex: 0.3,
      sortable: false,
      renderCell: (data: any) =>
        (
          <img
            style={{ height: "3rem", width: "3rem" }}
            src={`${APP_URLs.awsS3ImageURL}${data?.row?.Images[0]}`}
            onError={handleImageError}
          />
        ) ?? "N/A",
    },
    {
      field: "PostType",
      headerName: "Type",
      flex: 0.4,
      sortable: false,
      renderCell: (data) => data?.row?.PostType ?? "N/A",
    },
    {
      field: "Privacy",
      headerName: "Privacy",
      flex: 0.4,
      sortable: false,
      renderCell: (data) => data?.row?.Privacy ?? "N/A",
    },
    {
      field: "PostContent",
      headerName: "Post Content",
      flex: 0.4,
      sortable: false,
      renderCell: (data) => data?.row?.PostContent ?? "N/A",
    },
    {
      field: "ReportedCount",
      headerName: "Reported Count",
      flex: 0.4,
      sortable: false,
      renderCell: (data) => data?.row?.ReportedCount ?? "N/A",
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.2,
      sortable: false,
      headerAlign: "center",
      align: "right",
      renderCell: (data) => (
        <Box display="flex" alignItems="center">
            <Tooltip title="Delete">
              <DeleteSweepIcon
                onClick={() => {
                  setSelectedRow(data.row);
                  setShowDeleteConfirmModal(true);
                }}
                sx={{ fontSize: 22, ml: 1.7, cursor: "pointer" }}
                color="error"
              />
            </Tooltip>
        </Box>
      ),
    },
  ];

  const handlePagination = (values: { page: number; pageSize: number }) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    let query = {
      offset:
        pageSize == tableOptions?.limit ? (page === 0 ? 0 : `${page}` + 0) : 0,
      limit: pageSize ?? 10,
      search: debounceSearchValue ?? "",
    };
    getAllPosts(query);
  };

  const handleChangeFilters = (fieldName: string, value: string) => {
    setTableOptions((prevState: any) => {
      return {
        ...prevState,
        [fieldName]: value,
      };
    });
  };

  const deleteAdminPostFunc = async () => {
    let response = await deleteAdminPostAPI({ id: selectedRow?._id });
    if (response?.status) {
      snackActions.success(response?.message);
      handleCloseDelete(true);
      getAllPosts(tableOptions);
    } else {
      snackActions.error(response?.message);
    }
  };

  const handleCloseDelete = (isSubmit : boolean) => {
    if(isSubmit){
      getAllPosts(tableOptions);
    }
    setShowDeleteConfirmModal(false);
    setSelectedRow("");
  };

  return (
    <Card className="p-6">
      <>
        <KTCard>
          <MuiTable
            columns={columns}
            isAddEnable={false}
            data={postsData?.data?.docs ?? []}
            tableRef={tableRef}
            loading={isLoading}
            isBackEnable={false}
            isRowClickable={true}
            handlePagination={handlePagination}
            tableOptions={tableOptions}
            filters={
              <Grid container spacing={2} pb={3}>
                <Grid item lg={3} md={4} xs={12}>
                  <Box>
                    <label
                      className="form-label fw-bold"
                      style={{ marginLeft: 5 }}
                    >
                      Search By Name
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Search by name"
                      aria-label="Search"
                      onChange={(e: any) =>
                        handleChangeFilters("search", e?.target?.value)
                      }
                      value={tableOptions?.search}
                    />
                  </Box>
                </Grid>
              </Grid>
            }
          />
           {showDeleteConfirmModal && (
            <ConfirmEventPopup
              showPopup={showDeleteConfirmModal}
              handleClose={handleCloseDelete}
              handleConfirmed={deleteAdminPostFunc}
              loading={isDeleting}
              title={"Delete Post"}
              message={"Are you sure, want to delete this post?"}
            />
          )}
        </KTCard>
      </>
    </Card>
  );
};

export default Post;