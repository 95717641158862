import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import useDebounce from "../../hooks/useDebounce";
import {useMutation} from "react-query";
import {deleteRestrictedFishAreas, getAllRestrictedFishAreas} from "../../api";
import {GridColDef} from "@mui/x-data-grid";
import CustomTooltip from "../../_metronic/layout/components/common/CustomTooltip";
import {charValidate} from "../../components/utility";
import {Box, Grid, Tooltip} from "@mui/material";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import {resetFormData, resetStep, setRestrictedFishAreaFormData} from "../../store/slice/form/restrictedFishAreaForm";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import {snackActions} from "../../helpers/SnackUtilsConfigurator";
import {Button, Card} from "react-bootstrap";
import {KTCard, KTIcon} from "../../_metronic/helpers";
import MuiTable from "../../components/table/MuiTable";
import ConfirmEventPopup from "../../_metronic/layout/components/common/ConfirmEventPopup";
import AddEditFishArea from "./AddEditFishArea";
import VisibilityIcon from '@mui/icons-material/Visibility';
import FishesInfo from "./FishesInfo";

const RestrictedFishAreas: React.FC = () => {
  const [tableOptions, setTableOptions] = useState({
    offset: 0,
    limit: 10,
    hasNextPage: true,
    hasPrevPage: true,
    totalRowCount: undefined,
    search: "",
  });

  const tableRef = useRef();
  const dispatch = useDispatch();
  const debounceSearchValue: string = useDebounce(tableOptions?.search, 500);

  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

  const {
    mutateAsync: getRestrictedFishAreaAPI,
    data: restrictedFishAreaData,
    isLoading: isLoading,
  } = useMutation("restricted-fish-areas", getAllRestrictedFishAreas);

  const {
    mutateAsync: deleteRestrictedFishAreaAPI,
    isLoading: isDeleting
  } = useMutation("delete-restricted-fish-area", deleteRestrictedFishAreas);

  useEffect(() => {
    getRestrictedFishAreas(tableOptions);
  }, []);

  useEffect(() => {
    getRestrictedFishAreas({
      ...tableOptions,
      search: debounceSearchValue ? debounceSearchValue : "",
    });
  }, [debounceSearchValue]);

  const getRestrictedFishAreas = async (
    query:
      | {
      offset: number | string;
      limit: number;
      search: string;
    }
      | undefined
  ) => {
    let response = await getRestrictedFishAreaAPI(query);
    if (response?.status) {
      setTableOptions((prevState: any) => {
        return {
          ...prevState,
          offset: response?.data?.offset,
          limit: response?.data?.limit,
          hasNextPage: response?.data?.hasNextPage,
          hasPrevPage: response?.data?.hasPrevPage,
          totalRowCount: response?.data?.totalDocs,
        };
      });
    }
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 0.3,
      sortable: false,
      renderCell: (data) => (
        <CustomTooltip
          title={
            data?.row?.name ? (
              <h6 style={{ color: "white" }}>{data?.row?.name}</h6>
            ) : (
              "N/A"
            )
          }
        >
          <span>
            {data?.row?.name ? charValidate(data?.row?.name, 30) : "N/A"}
          </span>
        </CustomTooltip>
      ),
    },
    {
      field: "radius",
      headerName: "Radius",
      flex: 0.2,
      sortable: false,
      renderCell: (data) => (
        <CustomTooltip
          title={
            data?.row?.radius ? (
              <h6 style={{ color: "white" }}>{data?.row?.radius}</h6>
            ) : (
              "N/A"
            )
          }
        >
          <span>
            {data?.row?.radius}
          </span>
        </CustomTooltip>
      ),
    },
    {
      field: "latitude",
      headerName: "Latitude",
      flex: 0.2,
      sortable: false,
      renderCell: (data) => (
        <CustomTooltip
          title={
            data?.row?.latitude ? (
              <h6 style={{ color: "white" }}>{data?.row?.latitude}</h6>
            ) : (
              "N/A"
            )
          }
        >
          <span>
            {data?.row?.latitude}
          </span>
        </CustomTooltip>
      ),
    },
    {
      field: "longitude",
      headerName: "Longitude",
      flex: 0.2,
      sortable: false,
      renderCell: (data) => (
        <CustomTooltip
          title={
            data?.row?.longitude ? (
              <h6 style={{ color: "white" }}>{data?.row?.longitude}</h6>
            ) : (
              "N/A"
            )
          }
        >
          <span>
            {data?.row?.longitude}
          </span>
        </CustomTooltip>
      ),
    },
    {
      field: "fishes",
      headerName: "View Fishes",
      flex: 0.2,
      sortable: false,
      renderCell: (data) => (
        <div style={{ width: "100%" }}>
          <Box display="flex" alignItems="center" style={{marginLeft: "30px"}} onClick={() => {
            setSelectedRow(data.row);
            setShowInfoModal(true);
            dispatch(setRestrictedFishAreaFormData(data.row))
          }}>
            <VisibilityIcon sx={{ color: "#07427c" }} />
          </Box>
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.2,
      sortable: false,
      headerAlign: "center",
      align: "right",
      renderCell: (data) => (
        <Box display="flex" alignItems="center">
          <Tooltip title="Edit">
            <DriveFileRenameOutlineIcon
              onClick={() => {
                setSelectedRow(data.row);
                setShowAddEditModal(true);
                dispatch(setRestrictedFishAreaFormData(data.row))
              }}
              sx={{ fontSize: 22, cursor: "pointer", color: "#07427c" }}
            />
          </Tooltip>
          {/* {isSuperAdmin && ( */}
          <Tooltip title="Delete">
            <DeleteSweepIcon
              onClick={() => {
                setSelectedRow(data.row);
                setShowDeleteConfirmModal(true);
              }}
              sx={{ fontSize: 22, ml: 1.7, cursor: "pointer" }}
              color="error"
            />
          </Tooltip>
          {/* )} */}
        </Box>
      ),
    },
  ];

  const handlePagination = (values: { page: number; pageSize: number }) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    let query = {
      offset:
        pageSize == tableOptions?.limit ? (page === 0 ? 0 : `${page}` + 0) : 0,
      limit: pageSize ?? 10,
      search: debounceSearchValue ?? "",
    };
    getRestrictedFishAreas(query);
  };

  const handleChangeFilters = (fieldName: string, value: string) => {
    setTableOptions((prevState: any) => {
      return {
        ...prevState,
        [fieldName]: value,
      };
    });
  };

  const handleModalClose = (isSubmit: boolean) => {
    dispatch(resetStep());
    setShowAddEditModal(false);
    setShowInfoModal(false);
    setSelectedRow("");
    if (isSubmit) {
      getRestrictedFishAreas(tableOptions);
    }
  };

  const deleteRestrictedFishAreaFunc = async () => {
    console.log('Calling deleteRestrictedFishAreas');
    let response = await deleteRestrictedFishAreaAPI({ id: selectedRow?._id });
    console.log(response);
    if (response?.status) {
      snackActions.success(response?.message);
      handleCloseDelete();
      getRestrictedFishAreas(tableOptions);
    } else {
      snackActions.error(response?.message);
    }
  };

  const handleCloseDelete = () => {
    setShowDeleteConfirmModal(false);
    setSelectedRow("");
  };

  return (
    <Card className="p-6">
      <>
        <KTCard>
          <MuiTable
            columns={columns}
            isAddEnable={false}
            data={restrictedFishAreaData?.data?.docs ?? []}
            tableRef={tableRef}
            loading={isLoading}
            isBackEnable={false}
            isRowClickable={true}
            handlePagination={handlePagination}
            tableOptions={tableOptions}
            filters={
              <Grid container spacing={2} pb={3}>
                <Grid item lg={3} md={4} xs={12}>
                  <Box>
                    <label
                      className="form-label fw-bold"
                      style={{ marginLeft: 5 }}
                    >
                      Search By Name
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Search by name"
                      aria-label="Search"
                      onChange={(e: any) =>
                        handleChangeFilters("search", e?.target?.value)
                      }
                      value={tableOptions?.search}
                    />
                  </Box>
                </Grid>
                {/* {isSuperAdmin && ( */}
                <div className="ms-auto" style={{ marginTop: 43 }}>
                  <Button
                    className="d-flex align-items-center"
                    onClick={() => {setShowAddEditModal(true); dispatch(resetStep()); dispatch(resetFormData())}}
                  >
                    <KTIcon iconName="plus" className="fs-1" />
                    Add Restricted Fish Area
                  </Button>
                </div>
                {/* )} */}
              </Grid>
            }
          />
        </KTCard>
        {showInfoModal && (
          <FishesInfo
            show={showInfoModal}
            handleClose={handleModalClose}
          />
        )}
        {showAddEditModal && (
          <AddEditFishArea
            editData={selectedRow}
            show={showAddEditModal}
            handleClose={handleModalClose}
          />
        )}
        {showDeleteConfirmModal && (
          <ConfirmEventPopup
            showPopup={showDeleteConfirmModal}
            handleClose={handleCloseDelete}
            handleConfirmed={deleteRestrictedFishAreaFunc}
            loading={isDeleting}
            title={"Delete Restricted Fish Area"}
            message={"Are you sure, you want to delete this area?"}
          />
        )}
      </>
    </Card>
  );
};

export default RestrictedFishAreas;