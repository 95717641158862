import React, {useEffect, useState} from 'react';
import {Button, Col, Row} from "react-bootstrap";
import ReactQuill from "react-quill";
import {useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import {FormikProps, useFormik} from "formik";
import {
  prevStep,
  resetFormData,
  resetStep,
  setRestrictedFishAreaFormData
} from "../../store/slice/form/restrictedFishAreaForm";
import {Box, Divider} from "@mui/material";
import {useMutation} from "react-query";
import {addRestrictedFishAreas, getAllSpeciesList, updateRestrictedFishAreas} from "../../api";
import {Delete} from "@mui/icons-material";
import {snackActions} from "../../helpers/SnackUtilsConfigurator";

interface SpeciesState {
  speciesId: string;
  name: string;
  description: string;
}

const Step_1 = ({ handleClose, editData }: any) => {
  const dispatch = useDispatch();
  const formData: any = useSelector((state: any) => state?.restrictedFishAreas?.formData);

  const [speciesList, setSpeciesList] = useState<Array<SpeciesState>>([]);

  const {
    mutateAsync: getSpeciesListAPI,
    data: speciesData,
    isLoading: isLoading,
  } = useMutation("species-list", getAllSpeciesList);

  const {
    mutateAsync: addRestrictedFishAreaAPI,
    isLoading: isSubmitting
  } = useMutation("add-restrictedFishArea", addRestrictedFishAreas);

  const {
    mutateAsync: updateRestrictedFishAreaAPI,
    isLoading: isUpdating
  } = useMutation("update-restrictedFishArea", updateRestrictedFishAreas);

  useEffect(() => {
    getAllSpecies();

    if (formik.values.fishes) {
      setSpeciesList(formik.values.fishes);
    }
  }, []);

  const Step_1_validation_schema = Yup.object().shape({
    species: Yup.string().required("This field is required"),
    description: Yup.string().trim().required("Description is required"),
  });

  const formik = useFormik({
    initialValues: {
      fishes: formData?.fishes || [],
      species: formData?.species || "",
      description: formData?.description || "",
    },
    validationSchema: Step_1_validation_schema,
    onSubmit: async (values, { resetForm }) => {
      dispatch(setRestrictedFishAreaFormData(values));

      const selectedSpecies = values.species || "";
      const description = values.description || "";

      if (!selectedSpecies || !description) {
        return
      }

      const [speciesId, speciesName] = selectedSpecies.split('|');

      if (speciesId && speciesName) {
        setSpeciesList([...speciesList, {
          speciesId: speciesId,
          name: speciesName,
          description: description,
        }]);
        resetForm();
      }
    },
  });

  // Helper function to safely render form errors
  const renderError = (error: unknown) => {
    if (typeof error === 'string') {
      return <div className="fv-help-block">{error}</div>;
    }
    return null;
  };

  const getAllSpecies = async () => {
    await getSpeciesListAPI({ limit: 1000 });
  };

  const handleDeleteClick = (index: number) => {
    setSpeciesList(speciesList.filter((_, i) => i !== index));
  };

  const handleSubmitClick = async (formik: FormikProps<any>) => {
    if (speciesList.length === 0) {
      formik.setFieldError("species", "At least one item is required.");
      return;
    }

    const request = {
      id: editData?._id ? editData?._id : undefined,
      name: formData?.name || "",
      radius: formData?.radius || 0,
      latitude: formData?.latitude || 0,
      longitude: formData?.longitude || 0,
      fishes: speciesList || [],
    };

    console.log(request);

    let response;
    if (editData) {
      response = await updateRestrictedFishAreaAPI(request);
    } else {
      response = await addRestrictedFishAreaAPI(request);
    }

    console.log(response);

    if (response?.status) {
      snackActions.success(
        response?.message ?? "Form submitted successfully!"
      );
      handleClose(true);
      dispatch(resetFormData());
      dispatch(resetStep());
    } else {
      snackActions.error(response?.message ?? "Something went wrong!");
    }
  }

  return (
    <form onSubmit={formik.handleSubmit} noValidate className="form">
      <div className="p-7">
        <Row>
          <Col md={12} className="mb-4">
            <label className="form-label fw-bold">
              Select Species<span className="red-asterisk">*</span>
            </label>
            <div className="position-relative">
              <select
                className="form-control form-control-lg form-control-solid"
                {...formik.getFieldProps("species")}>
                <>
                  <option value="">Select species</option>
                  {speciesData && speciesData?.data?.docs?.map((item: {_id: string, name: string}) => {
                    return <option key={item._id} value={`${item._id}|${item.name}`}>{item.name}</option>
                  })}
                </>
              </select>
              {isLoading && (
                <div className="spinner-overlay">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              )}
            </div>
            {speciesList.length === 0 && formik.touched.species && formik.errors.species &&
              typeof formik.errors.species === "string" && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.species}
                  </div>
                </div>
              )}
          </Col>
          <Col sm={12} className="mb-4">
            <label className="form-label fw-bold">
              Enter Description<span className="red-asterisk">*</span>
            </label>
            <ReactQuill
              theme="snow"
              placeholder="Write something..."
              value={formik.values.description}
              modules={Step_1.modules}
              formats={Step_1.formats}
              onChange={(content) => formik.setFieldValue("description", content)}
            />
            {speciesList.length === 0 && formik.touched.description && formik.errors.description && (
              <div className="fv-plugins-message-container">
                {renderError(formik.errors.description)}
              </div>
            )}
          </Col>
          <Box display="flex" justifyContent="end">
            <Button type="submit" size="lg" variant="primary">
              Add
            </Button>
          </Box>
          {speciesList.length > 0 && (
            <>
              <Divider sx={{ marginTop: "10px", marginBottom: "10px" }} color="black" />
              <label className="form-label fw-bold">
                Fishes Info
              </label>
              <div>
                {speciesList.map((item, index) => (
                  <div key={index} className="border p-3 mb-2" style={{backgroundColor: "#f6f6f6"}}>
                    <div className="position-relative" style={{marginBottom: "7px"}}>
                      <h5>{index+1}. {item.name}</h5>
                      <button
                        className="delete-button"
                        onClick={() => handleDeleteClick(index)}>
                        <Delete />
                      </button>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: item.description }} />
                  </div>
                ))}
              </div>
            </>
          )}
        </Row>
      </div>
      <div
        className="modal-footer"
        style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          onClick={() => dispatch(prevStep())}
          size="lg"
          variant="secondary">
          Back
        </Button>
        <Button className="ms-3" size="lg" variant="primary" onClick={() => handleSubmitClick(formik)}>
          {editData ? isUpdating ? "Updating..." : "Update" : isSubmitting ? "Loading..." : "Submit"}
        </Button>
      </div>
    </form>
  );
};

Step_1.modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [{ color: [] }],
  ],
};

Step_1.formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
];

export default Step_1;