import {Box} from "@mui/material";
import {Modal} from "react-bootstrap";
import {KTIcon} from "../../_metronic/helpers";
import React from "react";
import {useSelector} from "react-redux";

interface SpeciesState {
  speciesId: string;
  name: string;
  description: string;
}

const FishesInfo = ({show, handleClose}: any) => {
  const formData: any = useSelector((state: any) => state?.restrictedFishAreas?.formData);

  return (
    <Box>
      <Modal
        id="kt_modal_create_app"
        tabIndex={-1}
        aria-hidden="true"
        dialogClassName="modal-dialog modal-dialog-centered mw-md-700px"
        show={show}
        backdrop="static">
        <div className="modal-header">
          <h2>Fishes Info</h2>
          {/* begin::Close */}
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => handleClose(false)}
          >
            <KTIcon className="fs-1" iconName="cross" />
          </div>
          {/* end::Close */}
        </div>

        <div className="modal-body">
          {formData.fishes.map((item: SpeciesState, index: number) => (
            <div key={index} className="border p-3 mb-2" style={{backgroundColor: "#f6f6f6"}}>
              <div className="mb-2">
                <h5>{index+1}. {item.name}</h5>
              </div>
              <div dangerouslySetInnerHTML={{ __html: item.description }} />
            </div>
          ))}
        </div>
      </Modal>
    </Box>
  );
};

export default FishesInfo;