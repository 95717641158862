import {useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import {useFormik} from "formik";
import {setRestrictedFishAreaFormData, nextStep} from "../../store/slice/form/restrictedFishAreaForm";
import {Button, Col, Row} from "react-bootstrap";

// Define form values interface
interface FormValues {
  name: string;
  radius: number;
  latitude: number;
  longitude: number;
}

const Step_0 = ({ handleClose }: any) => {
  const formData: any = useSelector((state: any) => state?.restrictedFishAreas?.formData);

  const Step_0_validation_schema = Yup.object().shape({
    name: Yup.string().trim().required("Name is required"),
    radius: Yup.number().moreThan(0, "Radius should be greater than 0.").required("Radius is required"),
    latitude: Yup.number().required("Latitude is required"),
    longitude: Yup.number().required("Longitude is required"),
  });

  const dispatch = useDispatch();

  const formik = useFormik<FormValues>({
    initialValues: {
      name: formData?.name || "",
      radius: formData?.radius?.toString() || "",
      latitude: formData?.latitude?.toString() || "",
      longitude: formData?.longitude?.toString() || "",
    },
    validationSchema: Step_0_validation_schema,
    onSubmit: async (values, { setFieldError }) => {
      if (values.latitude == 0) {
        setFieldError("latitude", "Latitude shouldn't be 0.");
        return;
      }

      if (values.longitude == 0) {
        setFieldError("longitude", "Longitude shouldn't be 0.");
        return;
      }

      dispatch(setRestrictedFishAreaFormData({ ...values }));
      dispatch(nextStep());
    },
  });

  // Helper function to safely render form errors
  const renderError = (error: unknown) => {
    if (typeof error === 'string') {
      return <div className="fv-help-block">{error}</div>;
    }
    return null;
  };

  return (
    <form onSubmit={formik.handleSubmit} noValidate className="form">
      <div className="p-7">
        <Row>
          <Col md={6} className="mb-4">
            <label className="form-label fw-bold">
              Enter Name<span className="red-asterisk">*</span>
            </label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid"
              placeholder="Name"
              aria-label="Name"
              {...formik.getFieldProps("name")}
            />
            {formik.touched.name && formik.errors.name && (
              <div className="fv-plugins-message-container">
                {renderError(formik.errors.name)}
              </div>
            )}
          </Col>
          <Col md={6} className="mb-4">
            <label className="form-label fw-bold">
              Enter Radius (miles)<span className="red-asterisk">*</span>
            </label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid"
              placeholder="Radius (miles)"
              {...formik.getFieldProps("radius")}
            />
            {formik.touched.radius && formik.errors.radius && (
              <div className="fv-plugins-message-container">
                {renderError(formik.errors.radius)}
              </div>
            )}
          </Col>
          <Col md={6} className="mb-4">
            <label className="form-label fw-bold">
              Enter Latitude<span className="red-asterisk">*</span>
            </label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid"
              placeholder="Latitude"
              {...formik.getFieldProps("latitude")}
            />
            {formik.touched.latitude && formik.errors.latitude && (
              <div className="fv-plugins-message-container">
                {renderError(formik.errors.latitude)}
              </div>
            )}
          </Col>
          <Col md={6} className="mb-4">
            <label className="form-label fw-bold">
              Enter Longitude<span className="red-asterisk">*</span>
            </label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid"
              placeholder="Longitude"
              {...formik.getFieldProps("longitude")}
            />
            {formik.touched.longitude && formik.errors.longitude && (
              <div className="fv-plugins-message-container">
                {renderError(formik.errors.longitude)}
              </div>
            )}
          </Col>
        </Row>
      </div>
      <div
        className="modal-footer"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Button
          onClick={handleClose}
          size="lg"
          variant="secondary"
        >
          Close
        </Button>
        <Button
          className="ms-3"
          type="submit"
          size="lg"
          variant="primary"
        >
          Save & Next
        </Button>
      </div>
    </form>
  );
};

export default Step_0;