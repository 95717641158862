import React, { useEffect, useState } from "react";
import { Box, Tooltip, Button, CircularProgress } from "@mui/material";
import { Card } from "react-bootstrap";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import errorFishImageJpg from '../../_metronic/assets/errorFishImage.jpg';
import { APP_URLs } from "../../constants/appURLs";
import { charValidate } from "../../components/utility";
import CustomTooltip from "../../_metronic/layout/components/common/CustomTooltip";
import { KTCard } from "../../_metronic/helpers";
import { useMutation } from "react-query";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import { addTFLiteAllModal, getTFTModalList, uploadPictureTFLite } from "../../api";
import CryptoJS from 'crypto-js';

const decryptPayload = (ciphertext: any, secretKey: any, iv: any) => {
  const key = CryptoJS.enc.Base64.parse(secretKey);
  const ivParsed = CryptoJS.enc.Base64.parse(iv);
  const decrypted = CryptoJS.AES.decrypt(ciphertext, key, { iv: ivParsed }).toString(CryptoJS.enc.Utf8);
  return JSON.parse(decrypted);
};
interface DecryptedResponseData {
  tflite?: string;
  metadata?: string;
  coreml?: string;
}
const Waters: React.FC = () => {
  const staticData = [
    { _id: "tflite", name: "TFLite model", image: "path/to/tflite-image.jpg" },
    { _id: "coreml", name: "CoreML model", image: "path/to/coreml-image.jpg" },
    { _id: "metadata", name: "TFLite MetaData", image: "path/to/metadata-image.jpg" },
  ];

  const [tableOptions, setTableOptions] = useState({
    offset: 0,
    limit: 10,
    hasNextPage: false,
    hasPrevPage: false,
    totalRowCount: staticData.length,
    search: "",
  });

  // Define selectedFiles with an index signature to allow string keys
  const [selectedFiles, setSelectedFiles] = useState<{ [key: string]: File | null }>({});
  const [uploadFilesData, setUploadFilesData] = useState<Record<string, any> | undefined>(undefined);
  const [loadingBtn, setLoadingBtn] = useState(false)


  const { mutateAsync: getWaterListAPI, data: watersData, isLoading } = useMutation("water-list", getTFTModalList);

  const getAllWaters = async (query: any) => {
    let response = await getWaterListAPI(query);
    if (response?.status) {
      setTableOptions((prevState) => ({
        ...prevState,
        offset: response?.data?.offset,
        limit: response?.data?.limit,
        hasNextPage: response?.data?.hasNextPage,
        hasPrevPage: response?.data?.hasPrevPage,
        totalRowCount: response?.data?.totalDocs,
      }));
    }
  };
  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement>) => {
    event.currentTarget.src = errorFishImageJpg;
  };

  // Handle file selection for each row
  const handleChangeImageUpload = (e: React.ChangeEvent<HTMLInputElement>, rowId: string) => {
    const file = e.target.files ? e.target.files[0] : null;
    setSelectedFiles((prevState) => ({
      ...prevState,
      [rowId]: file
    }));
  };

  // Define columns for DataGrid
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 0.4,
      sortable: false,
      renderCell: (data) => (
        <CustomTooltip
          title={data?.row?.name ? <h6 style={{ color: "white" }}>{data?.row?.name}</h6> : "N/A"}
        >
          <span>
            {data?.row?.name ? charValidate(data?.row?.name, 30) : "N/A"}
          </span>
        </CustomTooltip>
      ),
    },
    {
      field: "image",
      headerName: "File",
      flex: 0.3,
      sortable: false,
      renderCell: (data) => (
        <img
          style={{ height: "3rem", width: "3rem" }}
          src={`${APP_URLs.awsS3ImageURL}${data?.row?.image}`}
          onError={handleImageError}
        />
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "right",
      renderCell: (data) => (
        <Box display="flex" alignItems="center">
          <Tooltip title="Choose File">
            <input
              type="file"
              style={{ height: "2rem" }}
              onChange={(e) => handleChangeImageUpload(e, data.row._id)}
            />
          </Tooltip>
        </Box>
      ),
    },
  ];

  const columns1: GridColDef[] = [
    {
      field: "name",
      headerName: "Model Type",
      flex: 0.4,
      sortable: false,
      renderCell: (data) => (
        <CustomTooltip title={data.row.name}>
          <span>{charValidate(data.row.name, 30)}</span>
        </CustomTooltip>
      ),
    },
    // {
    //   field: "url",
    //   headerName: "Model URL",
    //   flex: 0.5,
    //   sortable: false,
    //   renderCell: (data) => (
    //     <CustomTooltip title={data.row.url}>
    //       <span>{charValidate(data.row.url, 30)}</span>
    //     </CustomTooltip>
    //   ),
    // },
    {
      field: "url",
      headerName: "Model URL",
      flex: 0.5,
      sortable: false,
      renderCell: (data) => {
        // Extract the last segment after the last '/' in the URL
        const fullUrl = data.row.url || ""; // default to an empty string if url is undefined
        const lastSegment = fullUrl.split("/").pop();
  
        return (
          <CustomTooltip title={fullUrl}>
            <>
            <span>{lastSegment || "N/A"}</span> {/* Display "N/A" if lastSegment is empty */}
            </>
          </CustomTooltip>
        );
      },
    },
    // {
    //   field: "uploadedAt",
    //   headerName: "Uploaded At",
    //   flex: 0.4,
    //   sortable: false,
    //   renderCell: (data) => new Date(data.row.uploadedAt).toLocaleString(),
    // },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.4,
      sortable: false,
      headerAlign: "center",
      align: "right",
      renderCell: (data) => (
        <Box display="flex" alignItems="center">
          <Tooltip title="Choose File">
            <input
              type="file"
              style={{ height: "2rem" }}
              onChange={(e) => handleChangeImageUpload(e, data.row._id)}
            />
          </Tooltip>
        </Box>
      ),
    },
  ];
  
  
  useEffect(() => {
    getAllWaters(tableOptions);
  }, []);

  // Submit function to upload each selected file individually
  const handleSubmit = async () => {

    setLoadingBtn(true);
    const formData = new FormData();
  
    // Collect all the selected files into the formData object
    for (const rowId in selectedFiles) {
      const file = selectedFiles[rowId];
      if (file) {
        formData.append(`${rowId}`, file);
      }
    }
  
    try {
      let response = await uploadPictureTFLite(formData);
      if (response?.status) {
        let decryptedResponseData: DecryptedResponseData = {}; // Specify the interface type

        if (response.data) {
          decryptedResponseData = decryptPayload(
            response?.data,
            process.env.REACT_APP_SECRET_KEY,
            process.env.REACT_APP_IV
          ) as any;
        }
        setUploadFilesData(decryptedResponseData);
     
    const payLoadData = {
      TFLiteModelUrl: decryptedResponseData.tflite,
      TFLiteMetaDataUrl: decryptedResponseData.metadata,
      CoreMLModelUrl: decryptedResponseData.coreml,
    };
       const res =  await addTFLiteAllModal(payLoadData);
       if (res?.status) {
        snackActions.success('All files uploaded successfully!');
        await getAllWaters(tableOptions);
       }
      } else {
        console.error('Error uploading files:', response.message);
        snackActions.error(response?.message ?? 'Issue uploading files, try again later!');
      }
    } catch (error) {
      console.error('Error uploading files:', error);
      snackActions.error('Issue uploading files, try again later!');
    }
    setLoadingBtn(false);
  };

const modelRows = watersData?.data
  ? [
      {
        _id: "tflite",
        name: "TFLite Model",
        url: watersData?.data.TFLiteModel,
        uploadedAt: watersData?.data.uploadedAt,
        updatedAt: watersData?.data.updatedAt,
        image: "path/to/tflite-image.jpg",
      },
      {
        _id: "coreml",
        name: "CoreML Model",
        url: watersData?.data.CoreMLModel,
        uploadedAt: watersData?.data.uploadedAt,
        updatedAt: watersData?.data.updatedAt,
        image: "path/to/coreml-image.jpg",
      },
      {
        _id: "metadata",
        name: "TFLite MetaData",
        url: watersData?.data.TFLiteMetaData,
        uploadedAt: watersData?.data.uploadedAt,
        updatedAt: watersData?.data.updatedAt,
        image: "path/to/metadata-image.jpg",
      },
    ]
  : staticData; // Fall back to staticData if watersData?.data is not available

  return (
    <Card className="p-6">
      <KTCard>
        <DataGrid
        columns={watersData?.data ? columns1 : columns}
        rows={modelRows}
        loading={isLoading}
        autoHeight
        disableColumnMenu
        disableColumnFilter
        hideFooterPagination
        getRowId={(row) => row._id}
        />

        <Box mt={2} textAlign="right">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={loadingBtn}  // Disable button while loading
            startIcon={loadingBtn ? <CircularProgress size={20} color="inherit" /> : null}  // Add spinner inside the button
          >
            Submit
          </Button>
        </Box>
      </KTCard>
    </Card>
  );
};

export default Waters;
