import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { Card, Row, Col, Button, Modal, Spinner } from "react-bootstrap"; // Add Row and Col for grid layout
import { getSpeciesById } from "../../api"; // Assuming this is still in use
import ArrowBack from '@mui/icons-material/ArrowBack'; 

const ImageDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState<string | null>(null);
  const [modalStatus, setModalStatus] = useState<string | null>(null);

  const {
    mutateAsync: fetchSpeciesDetails,
    data: speciesData,
    isLoading,
  } = useMutation("species-details", getSpeciesById);

  useEffect(() => {
    if (!id) {
      navigate("/dashboard");
    } else {
      fetchSpeciesDetails(id);
    }
  }, [id, navigate]);

  const handleImageClick = (image: string, status: string) => {
    setModalImage(image);
    setModalStatus(status);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalImage(null);
    setModalStatus(null);
  };

  if (isLoading) {
    return <Spinner animation="border" />;
  }

  if (!speciesData?.data || !Array.isArray(speciesData.data)) {
    return (
      <>
        <Button variant="primary" onClick={() => navigate(-1)} style={{ marginBottom: "10px" }}>
          <ArrowBack style={{ marginRight: "5px" }} /> Back
        </Button>
        <div>No data available.</div>
      </>
    );
  }

  return (
    <>
      <Button variant="primary" onClick={() => navigate(-1)} style={{ marginBottom: "10px" }}>
        <ArrowBack style={{ marginRight: "5px" }} /> Back
      </Button>
      <p style={{ 
        fontSize: '18px', 
        fontWeight: 'bold', 
        color: '#333', 
        margin: '10px 0', 
        textAlign: 'center' 
      }}>
        {localStorage.getItem("selectedSpeciesName") ?? "N/A"}
      </p>

      {speciesData?.data && (
        <Card className="p-6">
          <Row xs={1} md={4} className="g-4 justify-content-center">
            {speciesData.data.map((species: any) => (
              <Col key={species._id} className="d-flex justify-content-center">
                <Card className="text-center">
                  <Card.Img
                    variant="top"
                    src={species.image}
                    onClick={() => handleImageClick(species.image, species.status)}
                    style={{ height: "200px", objectFit: "cover", cursor: "pointer" }}
                  />
                  <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                    <Card.Title>{species.name}</Card.Title>
                    <Card.Text>
                      Status: {species.status === "annotate" ? "✔️ Annotated" : "❌ Not Annotated"}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>

          {/* Modal for Image Preview */}
          <Modal show={showModal} onHide={handleCloseModal} centered size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Image Preview</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
              {modalImage && (
                <>
                  <img
                    src={modalImage}
                    alt="Species Image"
                    style={{ width: "100%", maxHeight: "600px" }}
                  />
                  <div style={{ marginTop: "10px", fontSize: "20px" }}>
                    Status: {modalStatus === "annotate" ? "✔️ Annotated" : "❌ Not Annotated"}
                  </div>
                </>
              )}
            </Modal.Body>
          </Modal>
        </Card>
      )}
    </>
  );
};

export default ImageDetails;
