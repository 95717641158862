import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { APP_URLs, config, userToken } from "../../../constants/appURLs";
import { ILoginUserPayload } from "../../../constants/types";
import { snackActions } from "../../../helpers/SnackUtilsConfigurator";
import httpsClient from "../../../api/httpsClient";

// export const registerUser: any = createAsyncThunk(
//   "auth/register",
//   async (
//     {
//       firstName,
//       lastName,
//       email,
//       password,
//       confirmPassword,
//     }: IRegisterUserPayload,
//     { rejectWithValue },
//   ) => {
//     try {
//       const config = {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       };
//       await axios.post(
//         `${BASE_URL}/register`,
//         { firstName, lastName, email, password, confirmPassword },
//         config,
//       );
//       debugger;
//     } catch (error: any) {
//       // return custom error message from backend if present
//       if (error.response && error.response.data.message) {
//         return rejectWithValue(error.response.data.message);
//       } else {
//         return rejectWithValue(error.message);
//       }
//     }
//   },
// );

export const userLogin: any = createAsyncThunk(
  "auth/login",
  async ({ email, password }: ILoginUserPayload, { rejectWithValue }) => {
    try {
      const { data } = await httpsClient.post(
        APP_URLs.authEndPoints.login,
        { email, password },
        config,
      );
   
      if (data?.data?.token) {
        localStorage.setItem("userToken", data?.data?.token);
        localStorage.setItem("userInfo", JSON.stringify(data?.data));
        snackActions.success(data.message ?? "LoggedIn successfully");
        setTimeout(() => {
          window.location.reload();
        }, 500);
      } else {
        snackActions.dismissibleError(
          data?.message ?? "Something went wrong!, please try again",
        );
      }
    } catch (error: any) {
      snackActions.error(
        error.response.data.message ??
          "Something went wrong!, please try again",
      );
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const userLogout: any = createAsyncThunk(
  "auth/logout",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await httpsClient.put(
        APP_URLs.authEndPoints.logout,
        { token: userToken },
        config,
      );
      if (data?.status) {
        localStorage.clear();
        snackActions.success(data.message ?? "LoggedOut Successfully");
        window.location.reload();
      } else {
        snackActions.dismissibleError(data.message ?? "Something went wrong!");
      }
    } catch (error: any) {
      snackActions.error(
        error.response.data.message ??
          "Something went wrong!, please try again",
      );
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);
