/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { toAbsoluteUrl } from "../../_metronic/helpers";
import { ILoginUserPayload } from "../../constants/types";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { userLogin } from "../../store/slice/auth/authActions";
import CryptoJS from 'crypto-js';

const loginSchema: any = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
  acceptTerms: Yup.bool().required("You must accept the terms and conditions"),
});

const initialValues = {
  email: "",
  password: "",
  acceptTerms: false,
};

export function Login() {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const date = new Date();
  const currentYear = date.getFullYear();

  const formik: any = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const hashedPassword = CryptoJS.SHA256(values.password).toString(CryptoJS.enc.Hex);
      const payload: ILoginUserPayload = {
        email: values.email,
        password: hashedPassword,
      };
      await dispatch(userLogin(payload));
      setLoading(false);
    },
  });

  return (
    <form
      className=""
      onSubmit={formik.handleSubmit}
      noValidate
      id="kt_login_signin_form"
    >
      <div className="text-center mb-8">
        <div className="logo-box">
          <img className="img-fluid" src="../media/app-logo/logo.png" alt="App Logo" />
        </div>
        <h1 className="mb-2 mt-15">Sign In</h1>
        <p className="fs-20 m-0">Welcome to Fish Finder</p>
      </div>

      <div className="fv-row mb-3">
        <input
          placeholder="Email"
          {...formik.getFieldProps("email")}
          className={clsx(
            "form-control py-5",
            { "is-invalid": formik.touched.email && formik.errors.email },
            {
              "is-valid": formik.touched.email && !formik.errors.email,
            }
          )}
          type="email"
          name="email"
          autoComplete="off"
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <span role="alert">{formik.errors.email}</span>
          </div>
        )}
      </div>

      <div className="fv-row mb-3 position-relative">
        <input
          type={showPassword ? "text" : "password"}
          placeholder="Password"
          autoComplete="off"
          {...formik.getFieldProps("password")}
          className={clsx("form-control py-5")}
        />
        <div
          style={{
            position: "absolute",
            top: "15px",
            right: "15px",
            color: "#009ABC",
            cursor: "pointer",
          }}
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </div>
        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <span role="alert">{formik.errors.password}</span>
          </div>
        )}
      </div>

      <div className="d-flex flex-stack flex-wrap gap-3 mb-5">
        <div className="fv-row">
          <label
            className="form-check form-check-inline"
            htmlFor="kt_login_toc_agree"
          >
            <input
              className="form-check-input me-3"
              type="checkbox"
              id="kt_login_toc_agree"
              {...formik.getFieldProps("acceptTerms")}
            />
            <span>
              I Accept the{""}
              <a
                href={toAbsoluteUrl("/terms.html")}
                target="_blank"
                className="ms-1 link-primary fw-bold"
              >
                Terms & Conditions
              </a>
            </span>
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
        <Link to="/auth/forgot-password" className="link-primary fw-bold">
          Forgot Password ?
        </Link>
      </div>
      <div className="d-grid mb-5">
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-primary py-5"
          disabled={
            formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms
          }
        >
          {!loading && <span className="indicator-label">Login</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
      <p className="p-0 m-0 text-center">
        Copyright © {currentYear} Fish Finder All rights reserved.
      </p>
    </form>
  );
}
