/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { Link, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { toAbsoluteUrl } from "../../_metronic/helpers";
import { ContactUs } from "../../components/auth/ContactUs";
import { ForgotPassword } from "../../components/auth/ForgotPassword";
import { Login } from "../../components/auth/Login";
import { Registration } from "../../components/auth/Registration";
import { red } from "@mui/material/colors";

const AuthLayout = () => {
  const location = useLocation();

  useEffect(() => {
    document.body.classList.add("bg-body");
    return () => {
      document.body.classList.remove("bg-body");
    };
  }, []);

  return (
      <div className="position-relative d-flex p-lg-15 p-md-10 align-items-center p-5 login-form-container">
        <div className="bg-image-shape-box">           
            <img className="login-bg-image" src="../media/auth/bg-auth.jpg" alt="Login BG" />
            <img className="login-bg-shape" src="../media/auth/logo-shape.png" alt="Login BG Shape" />          
        </div> 
        <div className="g-0 h-100 px-5 px-md-10 px-lg-15 w-100">
          <div className="align-items-center d-flex h-100 justify-content-end">
            <div className="login-form-box mh-100 overflow-auto">
              <Outlet />
            </div>  
          </div>      
        </div>                       
      </div>            
  );
};

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path="login" element={<Login />} />
      <Route path="registration" element={<Registration />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="contactus" element={<ContactUs />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
);

export { AuthPage };
