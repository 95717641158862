import { Button, Col, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  nextStep,
  prevStep,
  setSpeciesFormData,
} from "../../store/slice/form/speciesForm";

const Step_1 = () => {
  const dispatch = useDispatch();

  const formDatas: any = useSelector((state: any) => state?.species?.formData);

  const Step_1_validation_schema = Yup.object().shape({
    lengthAvg: Yup.string().trim().required("Avg length is required"),
    lengthMax: Yup.string().trim().required("Max length is required"),
    weightMax: Yup.string().trim().required("Max weight is required"),
    weightAvg: Yup.string().required("Avg weight is required"),
    weightAvgMeasure: Yup.string().required("This field is required"),
    weightMaxMeasure: Yup.string().required("This field is required"),
    lengthMaxMeasure: Yup.string().required("This field is required"),
    lengthAvgMeasure: Yup.string().required("This field is required"),
  });

  const formik = useFormik({
    initialValues: {
      lengthAvg: formDatas?.lengthAvg || "",
      lengthMax: formDatas?.lengthMax || "",
      weightMax: formDatas?.weightMax || "",
      weightAvg: formDatas?.weightAvg || "",
      weightAvgMeasure: formDatas?.weightAvgMeasure || "",
      weightMaxMeasure: formDatas?.weightMaxMeasure || "",
      lengthMaxMeasure: formDatas?.lengthMaxMeasure || "",
      lengthAvgMeasure: formDatas?.lengthAvgMeasure || "",
    },
    validationSchema: Step_1_validation_schema,
    onSubmit: async (values) => {
      dispatch(setSpeciesFormData(values));
      dispatch(nextStep());
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} noValidate className="form">
      <div className="p-7">
        <Row>
          <Col md={6} className="mb-4">
            <label className="form-label fw-bold">
              Enter Average length<span className="red-asterisk">*</span>
            </label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid"
              placeholder="Average length"
              aria-label="Name"
              {...formik.getFieldProps("lengthAvg")}
            />
            {formik.touched.lengthAvg &&
              formik.errors.lengthAvg &&
              typeof formik.errors.lengthAvg === "string" && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.lengthAvg}</div>
                </div>
              )}
          </Col>
          <Col md={6} className="mb-4">
            <label className="form-label fw-bold">
              Select Average length measure
              <span className="red-asterisk">*</span>
            </label>
            <select
              className="form-control form-control-lg form-control-solid"
              {...formik.getFieldProps("lengthAvgMeasure")}
            >
              <option value="">Select measurement</option>
              <option value="feet">feet</option>
              <option value="feet/inches">feat and inches</option>
              <option value="meter">meters</option>
              <option value="inches">inches</option>
            </select>
            {formik.touched.lengthAvgMeasure &&
              formik.errors.lengthAvgMeasure &&
              typeof formik.errors.lengthAvgMeasure === "string" && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.lengthAvgMeasure}
                  </div>
                </div>
              )}
          </Col>
          <Col md={6} className="mb-4">
            <label className="form-label fw-bold">
              Enter Max length<span className="red-asterisk">*</span>
            </label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid"
              placeholder="Max length"
              aria-label="Max length"
              {...formik.getFieldProps("lengthMax")}
            />
            {formik.touched.lengthMax &&
              formik.errors.lengthMax &&
              typeof formik.errors.lengthMax === "string" && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.lengthMax}</div>
                </div>
              )}
          </Col>
          <Col md={6} className="mb-4">
            <label className="form-label fw-bold">
              Select Max length measure<span className="red-asterisk">*</span>
            </label>
            <select
              className="form-control form-control-lg form-control-solid"
              {...formik.getFieldProps("lengthMaxMeasure")}
            >
              <option value="">Select measurement</option>
              <option value="feet">feet</option>
              <option value="feet/inches">feat and inches</option>
              <option value="meter">meters</option>
              <option value="inches">inches</option>
            </select>
            {formik.touched.lengthMaxMeasure &&
              formik.errors.lengthMaxMeasure &&
              typeof formik.errors.lengthMaxMeasure === "string" && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.lengthMaxMeasure}
                  </div>
                </div>
              )}
          </Col>
          <Col md={6} className="mb-4">
            <label className="form-label fw-bold">
              Enter Max weight<span className="red-asterisk">*</span>
            </label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid"
              placeholder="Max weight"
              aria-label="Name"
              {...formik.getFieldProps("weightMax")}
            />
            {formik.touched.weightMax &&
              formik.errors.weightMax &&
              typeof formik.errors.weightMax === "string" && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.weightMax}</div>
                </div>
              )}
          </Col>
          <Col md={6} className="mb-4">
            <label className="form-label fw-bold">
              Select Max weight measure<span className="red-asterisk">*</span>
            </label>
            <select
              className="form-control form-control-lg form-control-solid"
              {...formik.getFieldProps("weightMaxMeasure")}
            >
              <option value="">Select weight measurement</option>
              <option value="pounds">Pounds</option>
              <option value="pounds/ounces">Pounds and Ounces</option>
              <option value="kg">Kilograms</option>
            </select>
            {formik.touched.weightMaxMeasure &&
              formik.errors.weightMaxMeasure &&
              typeof formik.errors.weightMaxMeasure === "string" && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.weightMaxMeasure}
                  </div>
                </div>
              )}
          </Col>
          <Col md={6} className="mb-4">
            <label className="form-label fw-bold">
              Enter Avg weight<span className="red-asterisk">*</span>
            </label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid"
              placeholder="Avg weight"
              {...formik.getFieldProps("weightAvg")}
            />
            {formik.touched.weightAvg &&
              formik.errors.weightAvg &&
              typeof formik.errors.weightAvg === "string" && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.weightAvg}</div>
                </div>
              )}
          </Col>

          <Col md={6} className="mb-4">
            <label className="form-label fw-bold">
              Select Avg weight measure<span className="red-asterisk">*</span>
            </label>
            <select
              className="form-control form-control-lg form-control-solid"
              {...formik.getFieldProps("weightAvgMeasure")}
            >
              <option value="">Select weight measurement</option>
              <option value="pounds">Pounds</option>
              <option value="pounds/ounces">Pounds and Ounces</option>
              <option value="kg">Kilograms</option>
            </select>
            {formik.touched.weightAvgMeasure &&
              formik.errors.weightAvgMeasure &&
              typeof formik.errors.weightAvgMeasure === "string" && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.weightAvgMeasure}
                  </div>
                </div>
              )}
          </Col>
        </Row>
      </div>
      <div
        className="modal-footer"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Button
          onClick={() => dispatch(prevStep())}
          size="lg"
          variant="secondary"
        >
          Back
        </Button>
        <Button className="ms-3" type="submit" size="lg" variant="primary">
          Save & Next
        </Button>
      </div>
    </form>
  );
};

export default Step_1;
